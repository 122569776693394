// Normalize CSS
@import "./assets/styles/normalize.scss";

// Override antd
@import "./assets/styles/override-antd.scss";

body {
  margin: 0;
  font-family: "proxima", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 1rem;
  font-weight: 400;
  color: #494949;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// Define CSS variables
:root {
  --small-font: 13px;
  --default-font: 15px;
  --footer-font: 28px;

  --white: #fff;
  --active: #FAA61A;
  --line: #dedede;

  --footer-text: #4c4c4c;
  --color-text: #494949;
  --color-triip: #5ec2c9;
  --color-title: #002E5E;
  --active-color-text: #FAA61A;
}

a {
  color: #4fbbbc;
  cursor: pointer;
  font-family: inherit;
  font-size: var(--default-font);

  transition: all .3s ease;
  text-decoration: none;
}

.hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-one {
  flex: 1;
}

.text-right {
  text-align: right;
}

button {
  outline: 0 !important;
}

// Global styles
img {
  vertical-align: middle;
}

h5.title-product {
  font-size: 26px;
  color: var(--color-title);
}