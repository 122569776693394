.mobile-view {
  display: none;
}

.desktop-view {
  display: block;
}

@media (max-width: 991px) {
  .mobile-view {
    display: block;
  }

  .desktop-view {
    display: none;
  }

  .app__header {
    padding: 0;

    .app__header--wrap {
      max-width: 100%;
    }

    .logo {
      position: absolute;
      top: 8px;
      left: 50%;
      width: 40px;
      margin-left: -20px;
      opacity: 1;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .header-menu-mobile {
      position: absolute;
      z-index: 1;
      left: 10px;
      top: 5px;
      min-width: 40px;
      height: 40px;
      padding: 0px;
      border: 0px;
      background: 0px center;
      outline: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        display: block;
        transform: scale(1.5);

        background: 0 0;
        border: none;
        color: var(--color-triip);
      }
    }

    .register-login {
      width: 100% !important;

      .triip-v3-non-search {
        display: flex;
        position: absolute;
        -webkit-box-align: center;
        align-items: center;
        top: 56px;
        left: 0;
        right: 0;
        z-index: 1;
        line-height: 36px;
        padding: 0px;
        margin-right: 0;
        background-color: rgb(255, 255, 255);
        border-radius: 3px;
        transition-duration: 0.3s;

        .ant-input-search {
          width: 100% !important;
        }
      }

      .triip-v3-cart-btn {
        position: absolute;
        top: 13px;
        right: 0;

        width: 24px;
        padding: 0;

        a {
          background-color: transparent;
          padding: 0;
          border-radius: 0;

          i {
            color: #48c2ca;
          }
        }
        
        span {
          font-size: 0.6rem;
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
        }
      }
    }

    &.fixed {
      height: 50px !important;

      .logo {
        opacity: 0;
      }

      .register-login {
        .triip-v3-non-search {
          position: fixed;

          top: 8px;
          left: 60px;
          right: 50px;
        } 
      }
    }
  }

  .header-menu-mobile-sub {
    min-width: 220px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-menu {
    padding: 0 15px !important;
  }
}

.header-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding: 0 0 0 50px;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;

    font-size: var(--default-font);
    color: var(--color-title);
    text-transform: uppercase;
    font-weight: bold;
    
    label {
      cursor: pointer;
    }

    &:hover {
      label {
        text-decoration: underline;
      }
    }

    &.active {
      color: var(--active-color-text);
    }
  }
}

.register-login {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .triip-v3-cart-btn {
    position: relative;
    background: 0 0;
    border: none;

    a {
      display: flex;
      align-items: center;
      align-items: center;

      background: #48c2ca;
      font-weight: 500;
      font-size: var(--default-font);
      color: #fff;
      padding: 6px 15px;
      border-radius: 9px;
      border: none;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;

      i {
        color: var(--white);
      }
    }

    span {
      position: absolute;
      top: -5px;
      right: -5px;
      background-color: var(--active);
      min-width: 24px;
      border: 2px solid var(--white);
      min-height: 24px;
      border-radius: 50%;
      font-size: 0.8rem;
      color: var(--white);
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .triip-v3-non-search {
    display: flex;
    align-items: center;

    margin-right: 10px;
    cursor: pointer;

    &.collapse {
      .ant-input-affix-wrapper {
        padding-left: 0;

        .ant-input {
          padding-left: 10px;
        }

        .ant-input-suffix {
          margin-left: 0;
  
          .ant-input-search-icon {
            margin-left: 0;
            width: 36px;
  
            &::before {
              display: none;
            }
          }
        }
      }
    }

    &.hidden-input {
      .ant-input {
        padding-left: 0 !important;
        visibility: hidden;
      }
    }
  }
}

.order-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 -15px;
  padding: 0 15px;

  background-color: var(--color-triip);

  span {
    color: var(--white);
    font-size: 17px;
    font-weight: bold;
  }

  a {
    position: absolute;
    left: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: var(--white);
      font-size: 30px;
    }
  }
}

.menu-mobile {
  background-color: var(--white);
  border: 1px solid #f0f0f0;

  h3 {
    padding: 15px 15px 0;
    font-weight: bold;
  }

  ul {
    border-top: 1px solid #f0f0f0;

    li {
      height: 24px !important;
      line-height: 24px !important;
    }
  }
}