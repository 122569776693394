.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.65);

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--white);
        width: 100px;
        height: 100px;
        border-radius: 12px;
    }

    &__icon {
        max-width: 60%;
    }
}