@mixin wrapContainer($minWidth, $breakpoint) {
  @media (min-width: $minWidth) {
    &__header,
    &__main,
    &__footer {
      &--wrap {
        max-width: $breakpoint;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@mixin wrapPaddingContainer($minWidth, $maxWidth, $padding) {
  @media (min-width: $minWidth) and (max-width: $maxWidth) {
    &__header,
    &__main,
    &__footer {
      &--wrap {
        padding: $padding;
      }
    }
  }
}

.app {
  &.ant-layout {
    background-color: var(--white);
  }

  &__header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;

    width: 100%;
    height: 100px !important;
    
    background: var(--white) !important;
    box-shadow: 0 6px 13px rgba(230, 230, 230, 0.5);
    line-height: 100px;

    &.fixed {
      position: fixed;
    }
  }

  &__main {
    margin-top: 100px;
    padding-bottom: 80px;
    min-height: 400px;
  }

  &__footer {
    background: var(--white);
    box-shadow: 0 -10px 10px rgba(240, 254, 255, 1);
  }

  @include wrapContainer(576px, 540px);
  @include wrapContainer(768px, 720px);
  @include wrapContainer(992px, 960px);
  @include wrapContainer(1200px, 1024px);

  @include wrapPaddingContainer(320px, 1024px, 0 15px);
}

@media (max-width: 767px) {
  .product-list__product-block__cards {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }

  .app__header--new {
    height: 60px !important;
    line-height: 60px;
  }

  .app__main--new {
    margin-top: 60px;
  }
}