.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);

  &-box {
    color: #949494;

    img {
      max-width: 140px;
      margin: auto;
      display: block;
    }

    &__logo {
      max-width: 400px !important;
      margin-bottom: 2vh !important;
      margin-top: 2vh !important;
    }

    &__notFound {
      max-width: 400px !important;
    }

    div {
      display: block;
      text-align: center;
      margin: 15px 0;
      font-size: 2rem;
    }

    &__title {
      height: 57px;
      font-family: Roboto;
      font-size: 48px !important;

      font-weight: 300;
      text-align: center;
      color: #444444;
    }

    &__content {
      height: 28px;
      font-family: Roboto;
      font-size: 24px !important;

      font-weight: 300;
      text-align: center;
      color: #4a4a4a;
    }

    &__button {
      display: block;
      margin: auto !important;
      text-align: center;

      a {
        color: white;
        background-color: rgb(63, 174, 41);
        line-height: 36px;
        border-radius: 100px;

        padding: 8px 25px;
        white-space: nowrap;
        text-decoration: none;
      }

      &:hover a {
        color: #fff;
        background-color: #0e730f;
      }
    }
  }
}
