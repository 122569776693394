@mixin updateMobileView($maxWidth) {
  @media (max-width: $maxWidth) {
    padding: 0;
    margin: 0 -30px;

    .ant-col {
      padding: 0 15px;

      &.ant-col-xs-12 {
        margin: 30px 0;
      }
    }

    &__block {
      h3 {
        font-size: 20px !important;
      }

      &-nav {
        margin-bottom: 15px !important;
      }
    }
  }
}

@mixin updateSmallLaptop($maxWidth) {
  @media (min-width: 768px) and (max-width: $maxWidth) {
    padding: 0;
    margin: 0 -30px;

    .ant-col {
      padding: 0;
    }

    &__block {
      h3 {
        font-size: 24px !important;
      }

      &-nav {
        margin-bottom: 15px !important;
      }
    }
  }
}

.footer {
  margin-left: -60px;

  .ant-col {
    padding: 0 40px;
  }

  @include updateMobileView(768px);
  @include updateSmallLaptop(992px);

  &__brand {
    width: 150px;
    margin: 0 auto;
    text-align: center;
  }

  &__block {
    h3 {
      color: var(--footer-text);
      font-weight: bold;
      margin: 0 0 20px;
      padding-bottom: 10px;
      position: relative;
      font-size: var(--footer-font);

      &:before {
        background-color: var(--line);
        content: "";
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 38px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }

    &-nav {
      display: block !important;
      list-style: none;
      margin: 0 0 10px;
      padding: 0;

      a {
        color: #939393;
        display: block;
        padding: 5px 0;

        &:hover {
          color: var(--color-triip);
          text-decoration: underline;
        }
      }

      li {
        &:first-child {
          a {
            padding-top: 0;
          }
        }
      }
    }

    &-social {
      li {
        display: inline-block;
        padding: 0 5px;
      }
    }

    &-linkapp {
      li {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
}